import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';

// Import components for each page
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';

const App: React.FC = () => {
    return (
        <div style={styles.container}>
            {/* Top header with buttons */}
            <header style={styles.header}>
                <nav style={styles.nav}>
                    <Link to="/" style={styles.button}>Home</Link>
                    <Link to="/about" style={styles.button}>About</Link>
                    <Link to="/contact" style={styles.button}>Contact</Link>
                </nav>
            </header>

            {/* Main panel */}
            <main style={styles.mainPanel}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                </Routes>
            </main>
        </div>
    );
};

// Basic styles for header and main panel
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#282c34',
        padding: '10px',
        marginBottom: '20px',
    },
    nav: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        margin: '0 10px',
        padding: '10px 20px',
        backgroundColor: '#61dafb',
        color: 'black',
        textDecoration: 'none',
        borderRadius: '5px',
        fontFamily: '"Roboto Mono", monospace',
    },
    mainPanel: {
        flex: 1,
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#f0f0f0',

    },
} as const;

export default App;
