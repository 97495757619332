import React from 'react';

const HomePage: React.FC = () => {
    return (
        <div className='container'>
            <div className='text-container'>
                <h1>Emre Tapcı</h1>
                <h2>Software Engineer | Architect | System Designer</h2>
                <h2>Full Stack | MERN | C# & ASP.NET core | Java & Spring</h2>
                <h2>Docker | Kubernetes | AWS</h2>
            </div>
            <div className='images-container'>
                <a href='https://cs.bilkent.edu.tr'>
                    <img src={`${process.env.PUBLIC_URL}/res/bilkent-university.png`} title='Bilkent University - Department of Computer Engineering and Information Science' alt='Bilkent University - Department of Computer Engineering and Information Science' width='100' height='100' />
                </a>
                <a href='https://bilgem.tubitak.gov.tr/en'>
                    <img src={`${process.env.PUBLIC_URL}/res/tubitak.png`} title='TÜBİTAK BİLGEM' alt='TÜBİTAK BİLGEM' width='85' height='100' />
                </a>
                <a href='https://milsoft.com.tr'>
                    <img src={`${process.env.PUBLIC_URL}/res/milsoft.png`} title='MilSOFT' alt='MilSOFT' width='85' height='100' />
                </a>
                <a href='https://aselsan.com.tr/en'>
                    <img src={`${process.env.PUBLIC_URL}/res/aselsan.png`} title='Aselsan' alt='Aselsan' width='120' height='24' />
                </a>
                <a href='https://www.ersteyazilim.com/en'>
                    <img src={`${process.env.PUBLIC_URL}/res/erste.png`} title='Erste Software' alt='Erste Software' width='100' height='66' />
                </a>
                <a href='https://group.r1se.com'>
                    <img src={`${process.env.PUBLIC_URL}/res/r1se.png`} title='R1SE Group' alt='R1SE group' width='100' height='48' />
                </a>
                <a href='https://weather.com'>
                    <img src={`${process.env.PUBLIC_URL}/res/twc.png`} title='The Weather Company' alt='The Weather Company' width='100' height='88' />
                </a>
                <a href='https://leg.colorado.gov'>
                    <img src={`${process.env.PUBLIC_URL}/res/colorado.png`} title='Colorado State General Assembly' alt='Colorado State General Assembly' width='100' height='100' />
                </a>
            </div>
        </div>
    );
};

export default HomePage;
