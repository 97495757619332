import React from 'react';

const AboutPage: React.FC = () => {
    return (
        <div className='container'>
            <div className='text-container'>
                <h2>
                    I am a seasoned Full-Stack Engineer with over two decades of experience working with clients across
                    Türkiye, Germany, the United Kingdom and the United States. Proficient in an array of technologies,
                    I possess a versatile skill set essential for tackling diverse software challenges. With a notable background
                    in developing platform software for military equipment as a key contractor on government projects in Türkiye,
                    I have honed my ability to deliver robust solutions in high-stakes environments. Passionate about continuous
                    learning and pushing the boundaries of software technology, I remain abreast of multiple development stacks,
                    technologies and paradigms. My enthusiasm for growth and innovation fuels my drive to excel in every project
                    I undertake, making me a valuable asset to any team.
                </h2>
                <h3>
                    I am excel on the following software stacks, technologies and paradigms:
                    <ul>
                        <li>NodeJS, ExpressJS, ReactJS, MaterialUI, ThreeJS</li>
                        <li>C#.NET, ASP.NET core, Entity Framework, CQRS</li>
                        <li>Java Spring framework, Hibernate, JPA</li>
                        <li>SOLID & ACID principles</li>
                        <li>Functional, dynamic, reactive programming</li>
                        <li>SQL databases, PostgreSQL, MySQL, MS SQL Server, Sequelize ORM</li>
                        <li>NoSQL databases, MongoDB, Mongoose ODM</li>
                        <li>Docker, Docker compose, Kubernetes</li>
                        <li>TDD, unit and integration testing</li>
                    </ul>
                </h3>
            </div>
        </div>
    );
};

export default AboutPage;
