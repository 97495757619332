import React, { useEffect, useState } from 'react';
import { promisify } from 'util';

const ContactPage: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmitting, setFormSubmitting] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const onSubmit = async (event: any) => {
        event.preventDefault();

        setFormSubmitting(true);

        const grecaptcha = (window as any).grecaptcha;
        await promisify(grecaptcha.ready)();
        const recaptchaKey = await grecaptcha.execute(process.env.REACT_APP_SITE_KEY, { action: 'contactme' });

        const response = await fetch('https://fsa6ahy7v5dmpttk2ymsecttqm0dlmib.lambda-url.us-east-1.on.aws/', {
            method: 'POST',
            body: JSON.stringify({
                name,
                email,
                message,
                recaptchaKey
            })
        });
        const responseStatus = response.status;
        const responseMessageFetched = (await response.json()).message;

        setFormSubmitted(true);
        if (responseStatus === 200) {
            setResponseMessage('Thank you for your message. I will contact you shortly.');
        }
        else {
            setResponseMessage(responseMessageFetched);
        }
        setName('');
        setEmail('');
        setMessage('');
        setFormSubmitting(false);
        setTimeout(() => setFormSubmitted(false), 10000);
    }

    useEffect(() => {
        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_SITE_KEY}`;
        document.body.appendChild(script);
    }, []);

    return <>
        <div
            className="g-recaptcha"
            data-size="invisible"
        />
        <div style={styles.container}>
            <h1 style={styles.title}>Contact Me</h1>
            <form style={styles.form} onSubmit={onSubmit}>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={styles.input}
                    placeholder='name'
                    required
                />
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                    placeholder='e-mail'
                    required
                />
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={styles.textarea}
                    required
                    placeholder='your message'
                />
                <button
                    type='submit'
                    style={{ ...styles.button, ...(formSubmitting && styles.buttonDisabled) }}
                    disabled={formSubmitting}
                >
                    {formSubmitting ? 'Sending...' : 'Send'}
                </button>
            </form>
            {formSubmitted && <h2 style={styles.successMessage}>{responseMessage}</h2>}
        </div>
    </>;
};

// Styles for Contact page and form
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        fontFamily: '"Roboto Mono", monospace',
    },
    title: {
        fontSize: '2rem',
        marginBottom: '20px',
    },
    text1: {
        fontSize: '1.5rem',
        marginBottom: '0px',
    },
    text2: {
        fontSize: '2rem',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '600px',
    },
    input: {
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontFamily: '"Roboto Mono", monospace',
    },
    textarea: {
        padding: '10px',
        marginBottom: '10px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        fontFamily: '"Roboto Mono", monospace',
        resize: 'vertical',
        minHeight: '100px',
    },
    button: {
        padding: '10px',
        backgroundColor: '#61dafb',
        color: 'black',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontFamily: '"Roboto Mono", monospace',
        marginBottom: '30px',
    },
    buttonDisabled: {
        backgroundColor: '#dedede',
        cursor: 'not-allowed'
    },
    successMessage: {
        fontSize: '1.2rem',
        color: '#4CAF50',
    },
} as const;

export default ContactPage;
